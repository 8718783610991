<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb :lastItemName="residence.name" />
      </b-col>

      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null,null,'comment')"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد دیدگاه
        </b-button>
      </b-col>
    </b-row>
    <div v-if="comments">
      <div class="row">
        <!-- <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" /> -->
        <!-- <search ref="provincesSearchComponent" class="w-50" v-model="searchInput" /> -->
      </div>
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <div v-for="(comment, index) in comments" :key="index">
          <b-card body-class="p-1" class="mt-0 mb-1 position-relative">
            <div style="top: 0; left: 0; z-index: 9" class="position-absolute">
              <b-button
                @click="showConfirmModal(comment, index, 'comment')"
                variant="flat-danger"
                class="font-weight-bolder"
              >
                <feather-icon icon="Trash2Icon" size="15" />
              </b-button>
              <b-button
                @click="showFormModal(comment, index, 'comment')"
                variant="flat-secondary"
                class="font-weight-bolder"
              >
                <feather-icon icon="Edit2Icon" size="15" />
              </b-button>
            </div>
            <div class="text-left d-flex justify-content-between">
              <div class="d-flex align-cneter">
                <b-avatar
                  variant="info"
                  :src="
                    comment.user.avatar.path
                      ? $http.defaults.fileUrl + comment.user.avatar.path
                      : ''
                  "
                  class="mr-1"
                  size="3rem"
                ></b-avatar>
                <div class="d-flex h-100 flex-column align-items-center">
                  <div class="mr-auto">{{ comment.user.first_name }}</div>
                  <b-badge class="text-xs"
                    ><small>{{
                      new Date(comment.created_at).toLocaleString("fa-IR", {
                        timeZone: "Iran",
                      })
                    }}</small></b-badge
                  >
                </div>
              </div>
              <div class="mt-1">
                <b-form-rating
                  id="rating-sm-no-border"
                  style="width: 3rem"
                  v-model="comment.sum_scores"
                  no-border
                  readonly
                  variant="warning"
                ></b-form-rating>
              </div>
            </div>

            <p class="py-1 mx-1">
              {{ comment.body }}
            </p>
          </b-card>

          <div
            v-for="(reply, replyIndex) in comment.reply_comment"
            :key="replyIndex"
          >
            <b-card body-class="p-1" class="mt-0 mb-1 ml-2 position-relative">
              <div
                style="top: 0; left: 0; z-index: 9"
                class="position-absolute"
              >
                <b-button
                  @click="showConfirmModal(reply, index, 'reply')"
                  variant="flat-danger"
                  class="font-weight-bolder"
                >
                  <feather-icon icon="Trash2Icon" size="15" />
                </b-button>
                <b-button
                  @click="showFormModal(reply, index, 'reply')"
                  variant="flat-secondary"
                  class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="15" />
                </b-button>
              </div>

              <div class="text-left d-flex justify-content-between">
                <div class="d-flex align-cneter">
                  <b-avatar
                    variant="info"
                    :src="
                      reply.user.avatar.path
                        ? $http.defaults.fileUrl + reply.user.avatar.path
                        : ''
                    "
                    class="mr-1"
                    size="3rem"
                  ></b-avatar>
                  <div class="d-flex h-100 flex-column align-items-center">
                    <div class="mr-auto">{{ reply.user.first_name }}</div>
                    <b-badge class="text-xs"
                      ><small>{{
                        new Date(reply.created_at).toLocaleString("fa-IR", {
                          timeZone: "Iran",
                        })
                      }}</small></b-badge
                    >
                  </div>
                </div>
              </div>

              <p class="py-1 mx-1">
                {{ reply.body }}
              </p>
            </b-card>
          </div>

          <b-button
            @click="collapsReply(index)"
            variant="outline-primary"
            size="sm"
            >پاسخ</b-button
          >

          <b-collapse
            :id="'collapse-' + index"
            class="mt-0 ml-4"
            v-model="comment.showReply"
          >
            <b-card class="bg-amber-500">
              <b-form-group v-if="respondentUsers.length > 0" class="col-md-6">
                <label>از طرف :</label>
                <v-select
                  v-model="comment.reply_comment_input.user_id"
                  :get-option-label="(option) => option.name"
                  :reduce="(user) => user.user_id"
                  :options="respondentUsers"
                >
                </v-select>
              </b-form-group>
              <b-form-group class="mt-1">
                <label for="commentItemType" class="text-capitalize"
                  >متن کامنت:</label
                >
                <b-form-textarea
                  id="textarea-default"
                  placeholder="پاسخ خود را بنویسید"
                  rows="7"
                  v-model="comment.reply_comment_input.body"
                ></b-form-textarea>
              </b-form-group>
              <b-button
                @click="
                  (editMode = false), submitForm(index, comment.id, 'reply')
                "
                variant="primary"
                >ارسال</b-button
              >
            </b-card>
          </b-collapse>
          <hr />
        </div>
      </b-overlay>
      <pagination
        ref="provincesPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش دیدگاه" : "ایجاد  دیدگاه" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container v-if="modalType == 'comment'" class="">
        <h4 class="mt-2 mb-1">از طرف :</h4>
        <div v-if="editMode">{{ commentInput.name }}</div>
        <b-form-group
          v-if="!editMode"
          label="جستجوی کاربر در"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-156546"
            v-model="selectedUser"
            :options="userOptions"
            size="sm"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            name="radio-btn-outline"
            buttons
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          v-if="!editMode"
          label="جستجو بر اساس "
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            size="sm"
            id="radio-group-2657657657"
            v-model="selectedSearchOption"
            :options="serachOptions"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            name="radio-btn-outline"
            buttons
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group class="mt-1">
          <!-- <b-form-input
            v-if="editMode"
            disabled
            v-model="ownerInput.full_name"
          ></b-form-input> -->

          <v-select
            v-if="!editMode"
            id="owner_user"
            class="bg-white"
            :clearable="false"
            :components="{ OpenIndicator }"
            v-model="commentInput.user_id"
            :get-option-label="(option) => option.full_name"
            :reduce="(user) => user.id"
            :options="users"
            @search="getUsersByRole"
            placeholder="برای جستجو تایپ کنید"
          >
            <template #option="{ full_name, mobile }">
              {{ full_name }}
              <br />
              <cite>{{ mobile }}</cite>
            </template>
          </v-select>
        </b-form-group>

        <hr />
        <b-form-group
          v-for="(item, index) in createdCommentItemsScores"
          :key="'cItems_' + index"
          class="mt-1"
        >
          <label for="rating-md-no-border">{{ item.name }}:</label>
          <b-form-rating
            variant="warning"
            id="rating-md-no-border"
            v-model="item.score"
            no-border
          ></b-form-rating>
        </b-form-group>

        <b-form-group class="mt-1">
          <label for="commentItemType" class="text-capitalize"
            >متن کامنت:</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="نظر خود را بنویسید"
            rows="7"
            v-model="commentInput.body"
          ></b-form-textarea>
        </b-form-group>
      </b-container>

      <b-container v-if="modalType == 'reply'" class="">
        <h4 class="mt-2 mb-1">از طرف :</h4>
        <div v-if="editMode">{{ editableReply.name }}</div>

        <hr />

        <b-form-group class="mt-1">
          <label for="commentItemType" class="text-capitalize"
            >متن کامنت:</label
          >
          <b-form-textarea
            id="textarea-default"
            placeholder="نظر خود را بنویسید"
            rows="7"
            v-model="editableReply.body"
          ></b-form-textarea>
        </b-form-group>
      </b-container>

      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm(null, null, null)">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BOverlay,
  BFormRating,
  BList,
  BListGroupItem,
  BBadge,
  BCollapse,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import StorageService from "@/services/storage";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    BOverlay,
    BFormRating,
    BList,
    BListGroupItem,
    BBadge,
    BCollapse,
  },
  data() {
    return {
      editableReply: null,
      modalType: "comment",
      showLoading: false,
      selectedCommentItemType: "residence",
      residence: {},
      perPage: 20,
      page: 1,
      comments: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      tableHeadings: [
        {
          title: "نام",
          slug: "name",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },

      commentInput: {
        name: "",
        type: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      commentInputTypes: [
        { value: "residence", name: "اقامتگاه" },
        { value: "tour", name: "تور" },
        { value: "experience", name: "تفریحات" },
        { value: "car", name: "ترانسفر" },
      ],
      commentItemTypesOptions: [
        { text: "اقامتگاه", value: "residence" },
        { text: "تور", value: "tour" },
        { text: "تفریحات", value: "experience" },
        { text: "ترانسفر", value: "car" },
      ],
      commentItems: [],
      createdCommentItemsScores: [],
      selectedUser: "user",
      selectedSearchOption: "name",
      userOptions: [
        { text: "مهمان ها", value: "user" },
        { text: "میزبان ها", value: "host" },
        { text: "ادمین ها", value: "admin" },
      ],
      serachOptions: [
        { text: "شماره همراه", value: "mobile" },
        { text: "نام", value: "name" },
        { text: "ایمیل", value: "email" },
      ],
      users: [],
      userData: null,
      respondentUsers: [],
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getComments();
    this.getCommentItems();
    this.getResidence();
    // this.getCompanies();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getComments();
    },
    perPage(value) {
      this.page = 1;
      this.getComments();
    },
    page(value) {
      this.getComments();
    },
  },
  methods: {
    getResidence() {
      const requestData = {
        method: "get",
        url: `/admin/${this.$route.params.type}s/${this.$route.params.id}`,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;

          this.residence = data.data;
          this.userData = StorageService.getUserData();
          this.respondentUsers = [
            {
              name: this.residence.host.full_name + "-میزبان",
              user_id: this.residence.host.id,
            },
            { name: this.userData.full_name, user_id: this.userData.id },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getComments();
    },

    async getComments() {
      this.showLoading = true;
     

      let data = {
        table: this.$route.params.type,
        id: this.$route.params.id,
        per_page: this.perPage,
        page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/comments/get_morph_data",
        params: data,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.comments = data.data;
          this.comments.forEach((element) => {
            (element.showReply = false),
              (element.reply_comment_input = {
                type_id: this.$route.params.id,
                type: this.$route.params.type,
                comment_id: element.id,
                body: "",
                user_id: 0,
              });
          });

          this.paginationData = data.meta;
          this.showLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showLoading = false;
        });
    },
    showConfirmModal(commentItem, index, type) {
      this.$bvModal
        .msgBoxConfirm("آیا از حذف دیدگاه مطمئن هستید ؟" + `: .`, {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmed) => {
          if (confirmed) {
            let provinceIndex = 0;
            if (type == "reply") {
              this.comments[index].reply_comment = await this.comments[
                index
              ].reply_comment.filter((provinceItem, index) => {
                if (provinceItem.id == commentItem.id) {
                  provinceIndex = index;
                }
                return provinceItem.id !== commentItem.id;
              });
            } else {
              this.comments = await this.comments.filter(
                (provinceItem, index) => {
                  if (provinceItem.id == commentItem.id) {
                    provinceIndex = index;
                  }
                  return provinceItem.id !== commentItem.id;
                }
              );
            }

            const requestData = {
              method: "delete",
              url: `/admin/comments/${commentItem.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(` دیدگاه:  با موفقیت حذف شد.`, {
                  title: this.$t("Done"),
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                if (type == "reply") {
                  this.comments[index].reply_comment.splice(
                    provinceIndex,
                    0,
                    commentItem
                  );
                } else {
                  this.comments.splice(provinceIndex, 0, commentItem);
                }
              });
          }
        });
    },
    showFormModal(comment, index, type) {
      this.modalType = type;

      if (type == "reply" && comment) {
        this.editableReply = {
          id: comment.id,
          user_id: comment.user.id,
          name: comment.user.full_name,
          body: comment.body,
        };
        this.editMode = true;
      } else {
        if (comment) {
          this.editMode = true;
          this.commentInput = {
            id: comment.id,
            user_id: comment.user.id,
            name: comment.user.full_name,
            body: comment.body,
          };
          this.createdCommentItemsScores = comment.scores;
        } else {
          this.editMode = false;
          this.commentInput = {
            name: "",
            type: "",
          };
        }
      }

      this.formModal = true;
    },
    submitForm(index, coment_id, type) {
      if (this.submitLoading) return;
      let errorMessage = null;

      // if (!errorMessage && !this.commentInput.name) {
      //   errorMessage = "لطفا نام آیتم دیدگاه را وارد کنید";
      // }
      // if (!errorMessage && this.commentInput.name.length < 2) {
      //   errorMessage = "نام آیتم دیدگاه نمی تواند کمتر از دو کاراکتر باشد";
      // }
      // if (!errorMessage && !this.commentInput.type) {
      //   errorMessage = "لطفا اسلاگ آیتم دیدگاه را وارد کنید";
      // }

      // if (errorMessage) {
      //   this.$bvToast.toast(errorMessage, {
      //     title: this.$t("Error"),
      //     variant: "danger",
      //     solid: true,
      //   });
      //   return;
      // }v
      if (type !== "reply") {
        var sum = 0;
        var length = 0;
        this.createdCommentItemsScores.forEach((items) => {
          sum = sum + items.score;

          if (items.score > 0) {
            length = length + 1;
          }
        });
        this.commentInput = {
          ...this.commentInput,
          type_id: this.$route.params.id,
          type: this.$route.params.type,
          scores: this.createdCommentItemsScores,
          sum_scores: sum / length,
        };
      } else {
      }
      this.submitLoading = true;
      var dataToSend = {};
      if (type == "reply") {
        dataToSend = this.comments[index].reply_comment_input;
      } else if (this.modalType == "reply") {
       
          var reply={...this.editableReply}
         reply.type=this.$route.params.type
         delete reply['id']
         delete reply['name']
        dataToSend = reply;
        
      } else {
        dataToSend = this.commentInput;
      }
      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode
          ? `/admin/comments/${
              this.modalType == "reply"
                ? this.editableReply.id
                : this.commentInput.id
            }`
          : `/admin/comments`,
        data: dataToSend,
      };
      (this.selectedCommentItemType = this.commentInput.type),
        this.$http(requestData)
          .then((response) => {
            let message = "آیتم دیدگاه با موفقیت اضافه شد";
            if (this.editMode) message = "آیتم دیدگاه با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            this.getComments();
            if (!this.editMode && this.comments && this.comments.length > 0) {
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getComments();
          })
          .catch((err) => {
            let message = "خطا در ایجاد آیتم دیدگاه";
            if (this.editMode) message = "خطا در ویرایش آیتم دیدگاه";
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
    async getCommentItems() {
      this.showLoading = true;
var param=""
if( this.$route.params.type=='car'){
  param='car'
}else if( this.$route.params.type=='events'){
  param='experience'
}else{
  param=this.$route.params.type
}
      let data = {
        // paginate: true,
        //  this.searchInput,
        // per_page: this.perPage,
        type: param,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/comment_items",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      await this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.commentItems = data.data;
          this.createInitialScores(this.commentItems);
          this.paginationData = data.meta;
          this.showLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showLoading = false;
        });
    },
    createInitialScores(commentItems) {
      commentItems.forEach((element) => {
        this.createdCommentItemsScores.push({
          item_id: element.id,
          score: 1.5,
          name: element.name,
        });
      });
      console.log(this.createdCommentItemsScores);
    },
    async getUsersByRole(e) {
      if (e == "") {
        return;
      }
      let data = {};
      if (this.selectedSearchOption == "name") {
        data = { "filter[name]": e };
      }
      if (this.selectedSearchOption == "mobile") {
        data = { "filter[mobile]": e };
      }
      if (this.selectedSearchOption == "email") {
        data = { "filter[email]": e };
      }
      const requestData = {
        method: "get",
        url: "/admin/users/" + this.selectedUser,
        params: data,
      };
      await this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.users = data.data;
          this.paginationData = data.meta;
          this.searchBasesubmitLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.searchBasesubmitLoading = false;
        });
    },
    collapsReply(index) {
      this.$nextTick(() => {
        this.comments.splice(index, 1, {
          ...this.comments[index],
          showReply: !this.comments[index].showReply,
        });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
